import React from 'react';
import { Link } from 'gatsby';

function Breadcrumbs() {
  const pathnames = typeof window !== 'undefined'
    ? window.location.pathname.split('/').filter((x) => x).map((pathname) => pathname.replace('.php', ''))
    : [];

  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((pathname, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return (
            <li key={routeTo}>
              {!isLast ? (
                <Link to={routeTo}>{pathname}</Link>
              ) : (
                <span>{pathname}</span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
