import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";

import Titlebar from "common/components/Titlebar";
import GoogleReviews from 'containers/Home/GoogleReviews';
import "common/assets/css/main-page.css";

import Seo from "components/seo";
import Layout from "../containers/Home/Layout";

const TestimonialsPage = () => {

  const seoData = {
    title: 'Testimonials & Reviews - Radiance Hair Studio ',
    description: 'If you are looking for a hair replacement, please have a look at the recent deliverables that we provided to our clients. For more information call us now.',
    keywords: ['Testimonials & Reviews'],
  };


  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
            <Titlebar title="Testimonials & Reviews" />
            <GoogleReviews />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default TestimonialsPage;