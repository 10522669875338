import React from "react";
import { useLocation } from '@reach/router';
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import ContactDetails from "../ContactDetails";
import Navbar from "containers/Home/Navbar";
import Footer from "containers/Home/Footer";
import { GlobalStyle, InteriorWrapper, ContentWrapper } from "containers/Home/global.style";
import { ResetCSS } from "common/assets/css/style";

export default function Layout({ children }) { 
  const location = useLocation();
  const hideComponent = location.pathname === '/appointment.php';
  console.log(location.pathname);
  return ( 

    <div className="layout-radiance">
        <ResetCSS />
        <GlobalStyle />
        <InteriorWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                <DrawerProvider>
                <Navbar />
                </DrawerProvider>
            </Sticky>
            <ContentWrapper>
                { children } 
            </ContentWrapper>
            {!hideComponent && <ContactDetails />}
            <Footer />
        </InteriorWrapper>
    </div> 
  ) 
}