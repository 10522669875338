import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay,} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Fade from "react-reveal/Fade";
import SectionWrapper, { Container } from "./googleReviews.style";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);



const GoogleReviews = () => {
  const allGoogleReviews = [
    {
      title: "Mrinal Mehta",
      image: "/MrinalMehta.png",
      description:
        "I would highly recommend them. They are doing awesome services and having very caring and helpful staff. They are the best in there work.Thank you team",
      date: "2 months ago",
    },
    {
      title: "Adarsh Shukla",
      image: "/Adarsh Shukla.png",
      description:
        "Good Experience. Staff were polite and service was done properly. Cost was bit high. I will highly recommend it.",
      date: "a week ago",
    },
    {
      title: "fahad asif",
      image: "/fahad asif.png",
      description:
        "If  u want to look the same as you were before then Radiance is the only place. You will never be disappointed and the place is cool great staff and they treat u very friendly manner. so please go there if u wanna feel the change and be young again",
      date: "a month ago",
    },
    {
      title: "Praveen",
      image: "/Praveen.png",
      description:
        "I recently had the pleasure of getting a customized hair wig for women in Delhi, and I couldn't be happier with the experience. The process was smooth, and the final result was nothing short of flawless. The wig matched my natural hair color and texture perfectly, making it virtually indistinguishable from my real hair. It's incredibly comfortable to wear and allows me to experiment with different styles effortlessly. If you're in Delhi and looking for a high-quality customized wig, this is the place to go.",
      date: "2 weeks ago",
    },
    {
      title: "Prem Pandey",
      image: "/Prem Pandey.png",
      description:
        "Hi, I have started using Hair Patch from Mar 22 from Radiance. The beginning was scary about the patch but I got used to it day by day.The technician were cooperative specially Deepak is patient enough to adjust the hair style which I want and fixed the same.Aatika is The person who explained me the process and explained me how  can maintain it properly for a longer life was really helpful and thanks to herIt is cost effective for the patch except the monthly maintenance cost though",
      date: "2 month ago",
    },
    {
      title: "Anand singh",
      image: "/Anand singh.png",
      description:
        "If you are looking for hair replacement, then Radiance is the right place. I would highly recommend this procedure. The results are amazing and it looks natural. I am extremely pleased with the results and overall experience. I wish I could add more stars (5 is not enough) to this review. Thank you for giving me a new look.",
      date: "2 month ago",
    },
    {
      title: "Mahesh LIC",
      image: "/Mahesh LIC.png",
      description:
        "Exceptional Hair Weaving in Delhi I recently experienced hair weaving for men in Delhi, and the results have been exceptional.The process was smooth, and the weave seamlessly blended with my existing hair.It not only added volume but also gave me a natural and fuller look.I feel like a new person, and the confidence boost is remarkable.Delhi's experts in hair weaving truly know their craft.",
      date: "2 weeks ago",
    },
    {
      title: "Shasan Ali",
      image: "/Shasan Ali.png",
      description:
        "I took a hair patch from this place.. these guys are amazing. I am very happy with there services.",
      date: "2 month ago",
    },
    {
      title: "praveen 2020",
      image: "/praveen 2020.png",
      description:
        "Delighted with Hair Patch in Delhi Getting a hair patch in Delhi has been one of the best decisions I've made. The process was comfortable, and the results were fantastic. The patch integrates seamlessly with my existing hair, and I can style it just like my own. It's a game- changer, and the compliments keep pouring in.Delhi's experts in hair patches have truly done an outstanding job.",
      date: "a week ago",
    },
    {
      title: "Sneha Mandal",
      image: "/Sneha Mandal.png",
      description:
        "Friendly and Good behaving Staff. Experienced Hair Stylists. Quality Products Worth for money.Overall Had a Great Experience",
      date: "a month ago",
    },
    {
      title: "Hanshika Marmat",
      image: "/HanshikaMarmat.png",
      description:
        "Delighted with Hair Patch in Delhi I recently got a hair patch in Delhi, and it's been a game-changer. The process was smooth, and the results are incredible. The patch matches my natural hair perfectly, and I can style it just like my own. It's comfortable to wear, and the confidence it has given me is priceless.Delhi's experts in hair patches have done an outstanding job, and I couldn't be happier.",
      date: "2 weeks ago",
    },
    {
      title: "Aditya Kumar",
      image: "/AdityaKumar.png",
      description:
        "Hi, I have started using Hair Patch from Mar 22 from Radiance. The beginning was scary about the patch but I got used to it day by day.The technician were cooperative specially Deepak is patient enough to adjust the hair style which I want and fixed the same.Aatika is The person who explained me the process and explained me how  can maintain it properly for a longer life was really helpful and thanks to herIt is cost effective for the patch except the monthly maintenance cost though",
      date: "3 month ago",
    },
    {
      title: "The Kaida",
      image: "/TheKaida.png",
      description:
        "I Would Highly Recommend Them.They are a very caring and wonderful staff..They are the best...See You Soon Thanks Again..",
      date: "a month ago",
    },
    {
      title: "Shivam Sharma",
      image: "/ShivamSharma.png",
      description:
        "I am regular a customer to Radiance Hair Studio..... Mr Deepak always guides me the choice my hair style he is very friendly and supportive guy always ensure to meet my requirements",
      date: "2 month ago",
    },
    {
      title: "Aanand Sharma",
      image: "/AanandSharma.png",
      description:
        "Non-surgical hair replacement for men in Delhi has truly been a game-changer for me. As someone who has been dealing with hair loss for years, I was skeptical at first. However, the results were nothing short of amazing. The procedure was painless, and the new hair seamlessly blended with my existing hair. I feel like I have a full head of hair again, and my confidence has skyrocketed. Delhi's experts in non-surgical hair replacement are doing incredible work, and I can't thank them enough.",
      date: "2 weeks ago",
    },
    {
      title: "payal dutta",
      image: "/payaldutta.png",
      description:
        "Exceptional Wig Selection in Delhi I recently explored the world of hair wigs in Delhi, and I'm thoroughly impressed. Delhi offers an outstanding variety of wigs to suit every need and style. Whether you're looking for a natural human hair wig or a synthetic one in a specific color or style, Delhi has it all.The wig sellers here are knowledgeable and helpful, making the shopping experience a breeze",
      date: "a week ago",
    },
  ];

  return (
    <SectionWrapper id="googleReviews">
      <Container className="myContainer">
        <div className="row">
          <div className="col-md-4">
          <Fade top>
              <img
                className="greviewimg showmob"
                width="404"
                height="434"
                src="/service-images/Reviewss.jpg"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
            <Fade left>
              <img
                className="greviewimg hidemob"
                width="404"
                height="434"
                src="/home/review.webp"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
          </div>
          <div className="col-md-8">
            <div
              className="reviewFold"
              id="googleReviews"
              style={{ display: "block" }}
            >
              <div className="flex">
                <Fade left>
                  <div className="leftGarea">
                    <h3 className="title">Highest Number of Google Rating in Delhi-NCR in This Segment</h3>
                    <p className="subheadstar">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <span>4.9 Rating of 2000+ Reviews</span>
                    </p>
                  </div>
                </Fade>
                <Fade right>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-default btn-lg black"
                    style={{ marginLeft: "auto" }}
                  >
                    Write a Review
                  </a>
                </Fade>
              </div>
              <div className="divider" style={{ margin: "15px auto" }}></div>
              <Fade bottom>
                <div
                  className="swiperOverfloww"
                  style={{ marginBottom: "60px" }}
                >
                  <div className="flex gReviewsIcos">
                    {/* Custom navigation buttons */}
                    <div className="swiper-button-prevG">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="swiper-button-nextG">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                  <Swiper
                    spaceBetween={30} // Adjust as needed
                    slidesPerView={2} // Display 4 slides per view
                    // autoplay={{ delay: 3000 }}
                    navigation={{
                      prevEl: ".swiper-button-prevG",
                      nextEl: ".swiper-button-nextG",
                    }}
                    // pagination={{ clickable: true }}
                    breakpoints={{
                      300: {
                        // width: 768,
                        slidesPerView: 1,
                      },
                      768: {
                        // width: 768,
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {allGoogleReviews.map((review, index) => (
                      <SwiperSlide key={index}>
                        <div className="gCard">
                          <div className="gHead">
                            <img
                              loading="lazy"
                              width="101"
                              height="101"
                              src={review.image}
                              alt={`Reviewimg for ${review.title}`}
                            />
                          </div>
                          <p className="reviewPara" id="scrollable">
                            <img
                              loading="lazy"
                              width="111"
                              height="111"
                              src="/home/quote.svg"
                              alt={`Reviewimg for ${review.title}`}
                            />
                            {review.description}
                          </p>
                          <div className="gFooter">
                            <div className="gHeadContent">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <h5>{review.title}</h5>
                              <p>{review.date}</p>
                            </div>
                            <img
                              width="68"
                              height="68"
                              src="/google.png"
                              alt="Review 1"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        
      </Container>
    </SectionWrapper>
  );
};

export default GoogleReviews;
