import styled from "styled-components";

const TitleBarWrap = styled.div`

    position: relative;
    background: #F7F2EF;

    h1.section-title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
    }

    ul {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    li {
        margin: 0 10px;
    }

    .container {
        padding: 40px 10px;
    }

    div#titleBar {
        display: block;
        position: relative;
        overflow: hidden;
    }

    #titleBar .background-image {
        background-image: url(/breadcrumb-bg.png);
        background-position: 50% 0%;
        background-size: auto;
        background-repeat: repeat-x;
        content: '';
        height: 200px;
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 0;
    }

    @media only screen and (max-width: 1440px) {
    
    }
    
    @media only screen and (max-width: 800px) {
        ul {
            flex-direction: column;
        }
        h1.section-title {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
        }
    }

`;

export default TitleBarWrap;