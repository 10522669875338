import React from 'react'
import Breadcrumbs from 'common/components/Breadcrumbs'
import TitleBarWrap from './titleBar.style'

export const Titlebar = (props) => {
  return (
    <TitleBarWrap >
    <div id="titleBar" className='row'>
        <div class="background-image"></div>
        <div className='container'>
            <div class="title-section">
                <h1 class="section-title">{props.title}</h1>
            </div>
            <Breadcrumbs />
        </div>
    </div>
    </TitleBarWrap>
  )
}

export default Titlebar;